<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <template v-if="loading">
            <Loading />
          </template>
          <template v-else>
            <div class="column is-12">
              <user-information
                :name="`${userDetail.first_name} ${userDetail.last_name}`"
                :email="userDetail.email"
                :registrationDate="userDetail.created_at"
                :status="userDetail.active"
                @update-customer-status-action="updateCustomerStatusAction()"
              />
            </div>
            <div class="column is-12">
              <div class="tabs">
                <ul>
                  <li
                    v-for="tab in tabList" :key="tab.id" @click="currentTab = tab.id"
                    :class="{ 'is-active' : tab.id === currentTab }"
                  >
                    <a>{{ tab.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <template v-if="currentTab === salesHistory">
              <!-- Agregar v-for -->
              <template v-for="(sale, index) in userDetail.sales">
                <div class="column is-12" :key="`sale-container-${index}`">
                  <accordion-sale-detail
                    :actual-sale="sale.data"
                  />
                </div>
              </template>
            </template>
            <template v-if="currentTab === quoteHistory">
              <!-- Agregar v-for -->
              <template v-for="(quote, index) in userDetail.quotes">
                <div class="column is-12" :key="`quote-container-${index}`">
                  <accordion-quotation-detail
                    :detail-quote="quote[1]"
                    :products-quote="quote[0]"
                  />
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserDetailMain',
  components: {
    Loading: () => import('@/components/UI/Loading.vue'),
    UserInformation: () => import('@/components/Users/Detail/UserInformation.vue'),
    AccordionSaleDetail: () => import('@/components/Users/Detail/AccordionSaleDetail.vue'),
    AccordionQuotationDetail: () => import('@/components/Quotation/Detail/AccordionQuotationDetail.vue')
  },
  data () {
    return {
      userId: null,
      loading: false,
      currentTab: 1,
      salesHistory: 1,
      quoteHistory: 2,
      tabList: [
        { id: 1, name: 'HISTORIAL DE VENTAS' },
        { id: 2, name: 'HISTORIAL DE COTIZACIONES' }
      ],
      userDetail: {}
    }
  },
  methods: {
    ...mapActions(['getCustomerInfo', 'updateCustomerStatus']),
    async getCustomerInfoAction () {
      const { success, customerInfo } = await this.getCustomerInfo(this.userId)
      if (success) { this.userDetail = customerInfo }
      this.loading = false
    },
    async updateCustomerStatusAction () {
      this.loading = true
      await this.updateCustomerStatus(this.userId)
      this.getCustomerInfoAction()
    }
  },
  beforeMount () {
    this.userId = this.$route.params.userId
    this.loading = true
    this.getCustomerInfoAction()
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .tabs ul {
      flex-direction: column;
      li, a {
        width: 100%;
      }
    }
  }
</style>
